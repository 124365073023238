import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Utility function to create a SHA-256 hash from user data
async function createHash(user) {
  const dataString = `${user.id}-${user.date_joined}-${user.email}`;
  const encoder = new TextEncoder();
  const data = encoder.encode(dataString);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

export default function Progressbar(props) {
  const [userHash, setUserHash] = useState("");
  const user = props.user.current.user;

  useEffect(() => {
    // Generate the hash when the component mounts
    async function generateHash() {
      const hash = await createHash(user);
      setUserHash(hash); // Set the generated hash to state
      console.log(hash); // Optional: Log the hash
    }

    generateHash(); // Call the async function
  }, [user]); // Dependency array includes 'user'

  // Steps with their corresponding tooltip values
  const steps = [
    { step: 1, href: "/student", tooltip: "Initial Onboarding", completed: true },
    { step: 2, href: "/student", tooltip: "Reading Material", disabled: true },
    {
      step: 3,
      href: `/quiz/${userHash}`,
      tooltip: "Psychometric Test",
      disabled: false,
    }, // Use the generated hash here
    {
      step: 4,
      href: "/student",
      tooltip: "Counselling Session",
      disabled: true,
    },
    { step: 5, href: "/student", tooltip: "Result of Test", disabled: true },
    { step: 6, href: "/careerlist", tooltip: "Career Paths", disabled: false },
  ];

  return (
    <div className="max-w-full mx-auto my-16 p-4 bg-[#e9edf5] rounded-lg md:scale-150">
      <h2 className="text-lg font-bold mb-4">Your Progress</h2>
      <div className="flex items-center justify-between">
        {steps.map((item, index) => (
          <div key={index} className="flex items-center relative group">
            {/* Tooltip Box */}
            <div className="absolute bottom-full w-fit left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block p-2 bg-white text-black text-sm font-bold shadow-xl rounded">
              {item.disabled ? "Buy To Unlock" : item.tooltip}
            </div>

            {/* Step Node */}
            <Link
              to={item.disabled ? "" : item.href}
              className={`w-10 h-10 flex items-center justify-center rounded-full shadow-xl border-1 border-black ${
                item.disabled
                  ? "bg-gray-300 text-black"
                  : item.completed
                  ? "bg-[#007bff] text-white hover:scale-125"
                  : "bg-[#e9edf5] text-black hover:scale-125"
              } font-bold transform transition-all duration-200`}
            >
              {item.step}
            </Link>

            {/* Line Between Nodes */}
            {item.step !== 6 && <div className="w-16 h-px bg-[#007bff]" />}
          </div>
        ))}
      </div>
    </div>
  );
}
